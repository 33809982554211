import BaseAction from '@/actions/base_action.js'

export default class extends BaseAction {
  execute(properties, component) {
    // See scopes#move
    $tpu.lock.acquire($tpu.keys.lckCardMove(), async () => {
      await this.moveTask(properties)
    }, (err, ret) => {
      // console.debug("Lock released", err, ret)
    })
  }

  async moveTask(properties) {
    const t = TrelloPowerUp.iframe()
    const formData = properties.formData
    const newIndex = formData.newIndex

    let taskId, destinationScopeId
    try {
      taskId = $tpu.keys.fromHtmTaskId(formData.taskId)
      destinationScopeId = $tpu.keys.fromHtmScopeId(formData.newScopeId)
    } catch(err) {
      console.error(err)
      return
    }

    const registry = await $tpu.r.cards.registry(t)

    const taskCard = registry[taskId]
    const sourceScopeId = taskCard.shapeUp.scopeCardId
    const sourceScopeCard = registry[sourceScopeId]
    const destinationScopeCard = registry[destinationScopeId]

    // These two variables might refer to the exact same array object.
    const sourceTaskCardIds = Array.from(sourceScopeCard.shapeUp.taskCardIds)
    const destinationTaskCardIds = (sourceScopeCard.id == destinationScopeCard.id) ? sourceTaskCardIds : Array.from(destinationScopeCard.shapeUp.taskCardIds || [])

    // Remove it first so we don't get confused when it gets re-inserted at
    // a different position.
    sourceTaskCardIds.remove(taskId);

    destinationTaskCardIds.splice(newIndex, 0, taskId);

    // Authoritative data needs to be saved first in order to prevent data loss due to unlinking.
    // See Task._link() for rationale.
    await $tpu.r.cards.setData(taskId, { scopeCardId: destinationScopeId }, t, false)

    if (sourceScopeId != destinationScopeId) {
      // Add a reference to the new Scope. It's better to disableTouch here as we don't want the UI to update
      // just yet.
      await $tpu.r.cards.setData(destinationScopeId, { taskCardIds: destinationTaskCardIds }, t, false)
    }

    // Save the removal of the reference from the old Scope
    await $tpu.r.cards.setData(sourceScopeId, { taskCardIds: sourceTaskCardIds }, t)

    this.refreshProjectShow()
  }
}
